import './App.css';
import Footer from './components/footer';
import InfoStat from './components/info_stat';
import Navbar from './components/Navbar';
import Product_Main_1 from './components/product_main_1';

function App() {
  return (
    <div className="App">
      <Navbar />
      <InfoStat />
      <Product_Main_1 />
      <Footer/>
    </div>
  );
}

export default App;
