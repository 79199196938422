import { ArrowPathIcon, BanknotesIcon, LockClosedIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Optimización de Costos',
    description:
      'Reduce gastos operativos con nuestras soluciones eficientes. Ofrecemos herramientas que optimizan tus recursos y maximizan el retorno de inversión, manteniendo la calidad y el rendimiento al más alto nivel.',
    href: '#',
    icon: BanknotesIcon,
  },
  {
    name: 'Soporte Continuo',
    description:
      'Ofrecemos soporte continuo para asegurar que tu sistema funcione sin problemas después del despliegue. Nuestro equipo está siempre disponible para resolver cualquier problema que pueda surgir.',
    href: '#',
    icon: ArrowPathIcon,
  },
  {
    name: 'Protección de Datos',
    description:
      'Garantiza la seguridad de tu información con nuestras soluciones avanzadas. Protegemos tus datos de accesos no autorizados y amenazas cibernéticas con medidas robustas y eficaces.',
    href: '#',
    icon: LockClosedIcon,
  },
]

export default function Product_Main_1() {
  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">Implementa Rápido</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Todo lo que necesitas para desplegar tu aplicación
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Simplificamos el proceso de despliegue con soluciones completas y eficientes. Desde la integración hasta la gestión, tenemos todo lo que necesitas para poner tu aplicación en funcionamiento de manera rápida y efectiva.
          </p>
        </div>
        <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                  <feature.icon aria-hidden="true" className="h-5 w-5 flex-none text-indigo-400" />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                    <a href={feature.href} className="text-sm font-semibold leading-6 text-indigo-400">
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
      <div className="bg-gray-900 py-16 sm:py-24">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-white/10 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
          <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Recibe una notificación cuando estemos lanzando algo.
          </h2>
          <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-white">
            Quieres saber más de nuestras novedades, ¡Registrate aqui!
          </p>
          <form className="mx-auto mt-10 flex max-w-md gap-x-4">
            <label htmlFor="email-address" className="sr-only">
              Ingresa tu correo
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              required
              placeholder="Ingresa tu correo"
              autoComplete="email"
              className="min-w-0 placeholder-white flex-auto rounded-md border-2 bg-gray-900/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-gray-900 focus:ring-white  sm:leading-6"
            />
            <button
              type="submit"
              className="flex-none rounded-md bg-gray-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-800"
            >
              Suscribirse
            </button>
          </form>
          <svg
            viewBox="0 0 1024 1024"
            aria-hidden="true"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
          >
            <circle r={512} cx={512} cy={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
            <defs>
              <radialGradient
                r={1}
                cx={0}
                cy={0}
                id="759c1415-0410-454c-8f7c-9a820de03641"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(512 512) rotate(90) scale(512)"
              >
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" stopOpacity={0} />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
    </div>
    
  )
}
